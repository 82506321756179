import React from 'react'
import styled from 'styled-components'

const Breadcrumb = styled.div`
  text-transform: uppercase;
  background: #141b22;
  margin-bottom: 2rem;

  width: 100%;
  .inner {
    padding: 1rem 0;
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;

    font-size: 12px;

    letter-spacing: 2px;

    display: flex;
    align-items: center;

    svg {
      margin: 0 0.6rem;
      color: #aaa;
      fill-rule: #aaa !important;
    }
  }

  a {
    color: #aaa;
    text-decoration: none;
  }

  @media (max-width: 700px) {
    .inner {
      padding: 1rem;
    }
  }
`

export default ({ children }) => (
  <Breadcrumb id="content">
    <div className="inner">{children}</div>
  </Breadcrumb>
)
