import React from 'react'

export default () => (
  <svg
    width="14px"
    height="11px"
    viewBox="0 0 14 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Desktop-HD"
        transform="translate(-271.000000, -705.000000)"
        fill="#D8D8D8"
      >
        <path
          d="M280.620805,711 L271,711 L271,709 L280.585786,709 L278,706.414214 L279.414214,705 L284.363961,709.949747 L283.973796,710.339913 L279.503416,715.071798 L278.060668,713.62905 L280.620805,711 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
)
