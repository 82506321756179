import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import Logo from './Logo'
import Navigation from './Navigation'
import { useScrollPosition } from './ScrollPosition'

const Header = ({ siteTitle = '', menus }) => {
  const [sticky, setSticky] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const isSticky = currPos.y < 0
      if (isSticky !== sticky) setSticky(isSticky)
    },
    [sticky]
  )

  return (
    <HeaderWrapper sticky={sticky}>
      <div className="wrapper">
        <h1>
          <Logo title={siteTitle} />
        </h1>

        <div className="focus">
          <div className="contact hide-m">
            <a href="tel:4406050700">440.605.0700</a>
            <a href="mailto:info@megatechway.com">info@megatechway.com</a>
            <a
              href="https://www.linkedin.com/company/mega-techway/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
          </div>
          <Navigation menus={menus} />
        </div>
      </div>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `MegaTech Way`,
}

const HeaderWrapper = styled.section`
  background: #fff;
  /* margin: 2.5vh auto; */
  /* margin-bottom: 4vh; */

  display: flex;

  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000;

  transition: all 250ms ease;

  @media (max-width: 700px) {
    .hide-m {
      display: none;
    }
  }

  h1 {
    margin: 0;
    margin-top: 5px;
  }

  .wrapper {
    width: 100%;
    max-width: 95vw;
    margin: 0 auto;
    padding: 1rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 250ms ease;

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  .focus {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: -10px;
    @media (max-width: 700px) {
      margin-top: 5px;
      width: 100%;
    }
  }

  .bottom {
    margin: 0 auto;
    padding: 4px;
  }

  .contact {
    font-weight: 400;
    font-size: 14px;

    margin-bottom: 0.75rem;
    position: relative;

    transition: all 250ms ease;

    a {
      color: #555;
      margin-left: 1rem;
      text-decoration: none;

      svg {
        fill: #555;
        width: 16px;
        position: relative;
        top: 7px;
      }
    }
  }

  ${({ sticky }) => {
    return sticky
      ? css`
          border-bottom: 1px solid #eceef1;
          box-shadow: 5px 5px 5px rgba(225, 227, 230, 0.16);
          /* background: rgba(255, 255, 255, 0.95); */

          .wrapper {
            padding: 0.5rem 0;
          }

          .contact {
            margin-bottom: 0.25rem;
          }
        `
      : ''
  }}
`

export default Header
