import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/Breadcrumb'
import Arrow from '../components/Arrow'

import { Wrapper, Content, Boom } from '../templates/page'

export const backgroundImage = ({ featured_media }) => {
  if (featured_media === null) return

  return featured_media.localFile.childImageSharp.fluid.src
}

export default ({ data }) => {
  // const { title, content, acf, featured_media } = data.wordpressPage

  const { edges: posts } = data.allWordpressPost

  return (
    <Layout>
      <SEO
        title="Case Studies"
        description="A library of case studies describing cable assembly design, manufacturing and logistics challenges Mega Techway has met with other customers; we can do the same for you."
      />
      <Boom backgroundImage={'/case_studies.png'}>
        <div className="overlay">
          <div className="inner">
            <h1>Case Studies</h1>
          </div>
        </div>
      </Boom>

      <Breadcrumb>
        <Link to="/">Home</Link> <Arrow /> <b>Case Studies</b>
      </Breadcrumb>

      <Wrapper>
        <Content className="content">
          <AlternateSides>
            {posts.map(({ node: post }) => (
              <Alternate key={post.id}>
                <div className="child main">
                  <div className="inner">
                    <p>{post.title}</p>
                    <Link to={post.slug}>Read Case Study</Link>
                  </div>
                  <BackgroundImage image={backgroundImage(post)} />
                </div>
                <div className="child">
                  <p
                    className="text"
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                </div>
              </Alternate>
            ))}
          </AlternateSides>
        </Content>
      </Wrapper>
    </Layout>
  )
}

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  background: url(${props => props.image});
  background-size: cover;
  background-repeat: none;
  opacity: 0.4;
  z-index: 1;
  position: absolute;

  transition: opacity cubic-bezier(0.68, 0, 0.265, 1) 0.4s,
    transform 0.45s cubic-bezier(0.68, 0, 0.265, 1) 0.4s;

  &:hover {
    opacity: 0.8;
  }
`

const AlternateSides = styled.div`
  display: flex;
  flex-direction: column;
`
const Alternate = styled.div`
  display: flex;

  text-align: center;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  .child {
    flex: 1;
    padding: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    line-height: 32px;
    /* text-align: center; */
    width: 75%;
    font-size: 16px;
  }

  .main {
    min-height: 500px;
    background-color: #141b22;
    color: #fff;

    .inner {
      flex-direction: column;
      text-align: center;
      display: flex;
      align-items: center;
      z-index: 100;
      justify-content: center;
    }

    font-size: 24px;
    font-weight: bold;

    position: relative;

    p {
      max-width: 75%;
    }

    a {
      background: #fff;
      color: #141b22;
      font-weight: bold;
      border-radius: 3px;
      padding: 4px 24px;
      text-transform: uppercase;
      font-size: 16px;

      text-decoration: none;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;

    &:nth-child(even) {
      flex-direction: column;
    }

    .text {
      width: 100%;
    }
  }
`

export const query = graphql`
  query {
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "case-study" } } } }
    ) {
      edges {
        node {
          id
          title
          content
          excerpt
          slug

          path

          featured_media {
            id
            source_url
            localFile {
              id
              childImageSharp {
                id
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`
