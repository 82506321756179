import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import * as Yup from 'yup'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/Breadcrumb'
import Arrow from '../components/Arrow'

import { Wrapper, Content, Boom } from '../templates/page'
import { Sidebar } from '../components/Sidebar'
import { backgroundImage } from './case_studies'
import { Formik } from 'formik'
import axios from 'axios'

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default ({ data }) => {
  const [message, setMessage] = useState('')
  const { title, content, acf, ...page } = data.wordpressPage

  const submitData = (values, actions) => {
    // console.log(values)
    // axios
    //   .post('/', values)
    //   .then(response => {

    //   })
    //   .catch(error => console.log(error))

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values }),
    })
      .then(response => {
        console.log(response)
        setMessage('Thanks for reaching out, we will be in contact soon.')
      })
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Boom backgroundImage={backgroundImage(page)}>
        <div className="overlay">
          <div className="inner">
            <h1>{title}</h1>
          </div>
        </div>
      </Boom>

      <Breadcrumb>
        <Link to="/">Home</Link> <Arrow /> <b>{title}</b>
      </Breadcrumb>

      <Wrapper>
        <Content
          className="content"
          // dangerouslySetInnerHTML={{ __html: content }}
        >
          {message.length ? (
            <div style={{ textAlign: 'center' }}>{message}</div>
          ) : (
            <Formik
              validationSchema={ContactSchema}
              onSubmit={submitData}
              initialValues={{
                name: '',
                email: '',
                company: '',
                message: '',
                file: '',
              }}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form
                  netlify
                  method="POST"
                  name="contact"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <p style={{ display: 'none' }}>
                    <label>
                      Don’t fill this out if you're human:{' '}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="contact" />
                  {/* <input type="hidden" name="_wpcf7" value="98" />
                  <input type="hidden" name="_wpcf7_version" value="5.1.4" />
                  <input type="hidden" name="_wpcf7_locale" value="en_US" />
                  <input
                    type="hidden"
                    name="_wpcf7_unit_tag"
                    value="wpcf7-f98-o1"
                  />
                  <input type="hidden" name="_wpcf7_container_post" value="0" />
                  */}

                  <div>
                    <label>
                      Name*
                      {errors.contact && touched.contact ? (
                        <div>{errors.contact}</div>
                      ) : null}
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                        />
                      </span>
                    </label>
                  </div>
                  <br />
                  <div>
                    <label>
                      Email Address*
                      {errors.email && touched.email ? (
                        <div>{errors.email}</div>
                      ) : null}
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                          aria-required="true"
                          aria-invalid="false"
                        />
                      </span>{' '}
                    </label>
                  </div>
                  <br />
                  <div>
                    <label>
                      Company{' '}
                      <span className="wpcf7-form-control-wrap your-company">
                        <input
                          type="text"
                          name="company"
                          value={values.company}
                          onChange={handleChange}
                          size="40"
                          className="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                        />
                      </span>{' '}
                    </label>
                  </div>
                  <br />
                  <div>
                    <label>
                      How can we help you?
                      {errors.contact && touched.contact ? (
                        <div>{errors.contact}</div>
                      ) : null}
                      <span className="wpcf7-form-control-wrap your-message">
                        <Textarea
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          cols="40"
                          rows="10"
                          className="wpcf7-form-control wpcf7-textarea"
                          aria-invalid="false"
                        />
                      </span>
                    </label>
                  </div>
                  <br />
                  <div>
                    <label>
                      Supporting Documents
                      <span className="wpcf7-form-control-wrap file-903">
                        <input
                          id="file"
                          name="file"
                          type="file"
                          style={{}}
                          onChange={event => {
                            setFieldValue('file', event.currentTarget.files[0])
                          }}
                          size="40"
                          className="wpcf7-form-control wpcf7-file"
                          accept=".png,.pdf,.jpg,.doc,.docx"
                          aria-invalid="false"
                        />
                      </span>
                    </label>
                  </div>
                  <br />
                  <div>
                    <ContactButton type="submit">Submit</ContactButton>
                  </div>
                  <div className="wpcf7-response-output wpcf7-display-none" />
                </form>
              )}
            </Formik>
          )}
        </Content>

        <Sidebar dangerouslySetInnerHTML={{ __html: acf.sidebar }} />
      </Wrapper>
    </Layout>
  )
}

const ContactButton = styled.button`
  background: #141b22;

  color: #fff !important;

  padding: 6px 24px;
  font-size: 14px;

  font-weight: bold !important;
  border-radius: 3px;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const Textarea = styled.textarea`
  display: block;
  width: 100%;
`

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "contact" }) {
      title
      content
      acf {
        sidebar
      }
      date(formatString: "MMMM DD, YYYY")
      slug

      featured_media {
        id
        source_url
        localFile {
          id
          childImageSharp {
            id
            fluid(maxHeight: 1200) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`
