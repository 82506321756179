import React from 'react'

import styled from 'styled-components'
import { Link } from 'gatsby'
import Arrow from './Arrow'

const ContactWrappr = styled.section`
  form {
    h3 {
      text-transform: uppercase;
      margin: 0;
      font-size: 22px;
      margin-bottom: 12px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
    }

    max-width: 750px;
    margin: 0 auto;

    .inputGroup {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;

      input {
        flex: 1;
        width: 100%;
        padding: 3px 5px;
        font-size: 14px;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    textarea {
      width: 100%;
      min-height: 75px;
      padding: 5px;
      font-size: 14px;
    }
  }

  a {
    background: #333;
    color: #fff !important;

    padding: 6px 24px;
    font-size: 14px;

    font-weight: bold !important;
    border-radius: 3px;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 700px) {
    }

    svg {
      margin-left: 1rem;
      color: #fff;
      fill: #fff;
      margin-top: 4px;
    }
  }
`

export default () => (
  <ContactWrappr>
    <h3>Ready to start a new project?</h3>
    <Link to="/contact">
      Contact us today <Arrow />
    </Link>
  </ContactWrappr>
)
